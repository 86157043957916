import React from 'react';
import { NavLink } from 'reactstrap';

import DownArrow from '../../assets/images/down-arrow.png';
import UpArrow from '../../assets/images/up-arrow.png';


function Trigger({ onClick, filter, active, label }) {
  return (
    <NavLink
      className="d-flex justify-content-between"
      onClick={onClick}
      data-filter={filter}
      href="#"
      active={active === filter}
    >
      <span>{label}</span>
      <img src={active === filter ? UpArrow : DownArrow} alt="" />
    </NavLink>
  );
}

export default Trigger;
