import React from 'react';


function Image({ id, sm, md, alt, className, style }) {
  return (
    <picture id={id}>
      <source media="(max-width: 799px)" srcSet={sm} />
      <source media="(min-width: 800px)" srcSet={md} />
      <img src={md} alt={alt} className={className} style={style} />
    </picture>
  );
}

Image.defaultProps = {
  alt: '',
};

export default Image;
