import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import { Container, Button, Row, Col } from 'reactstrap';

import Image from '../components/Image';
import { Heading1, Paragraph, Span } from '../components/text';
import meta from '../data/meta.json';


function DeliveryBanner() {
  const { sandwich } = useStaticQuery(graphql`
    {
      sandwich:file(relativePath: { eq: "sandwich.png" }) {
        sizes:childImageSharp {
          sm: resize(width: 400, toFormat: PNG, pngQuality: 90) {
            src
          }
          md: resize(width: 640, toFormat: PNG, pngQuality: 90) {
            src
          }
        }
      }
    }
  `);

  return (
    <Container fluid>
      <Row className="d-flex align-items-center mb-4" noGutters>
        <Col xs={12} md={4} className="d-flex justify-content-center mb-3 mb-md-0">
          <Image sm={sandwich.sizes.sm.src} md={sandwich.sizes.md.src} className="w-100" />
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-start justify-content-md-center">
          <Heading1 sm={2} md={3} className="m-0">
            we now <br />
            <Span className="text-secondary" sm={3} md={5} weight={700}>DELIVER!</Span>
          </Heading1>
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-start justify-content-md-center">
          <Button tag={Link} to="/menu" className="text-primary px-3 py-2">
            <Span sm={1.5} md={2.25} weight={700}>CLICK FOR MENU</Span>
          </Button>
        </Col>
      </Row>
      
      <Row className="bg-secondary py-2 mb-2">
        <Col className="px-md-6">
          <Paragraph
            sm={1.3}
            md={1.4}
            weight={700}
            className="text-black text-uppercase m-0"
          >
            <span className="d-none d-md-block">
              DELIVERY BETWEEN {meta.deliveryHours} | {meta.deliveryDays}
            </span>
            <span className="d-block d-md-none">
              DELIVERY BETWEEN <br />{meta.deliveryHours} | {meta.deliveryDays}
            </span>
          </Paragraph>
        </Col>
      </Row>
      
      <Row>
        <Col className="px-md-6">
          <Paragraph sm={1} md={1.4} weight={700}>
            DIAL 226-SUBS (7827) <span className="text-secondary">AND ENTER STORE EXT. OR USE DIRECT DIAL NUMBER</span>
          </Paragraph>
        </Col>
      </Row>
    </Container>
  );
}

export default DeliveryBanner;
