import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';


function RegionMenu({ active, onClick }) {
  return (
    <Nav vertical>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter={null}
          active={active === undefined}
        >
          All
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="West"
          active={active === 'West'}
        >
          West
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="Port Of Spain"
          active={active === 'Port Of Spain'}
        >
          Port Of Spain
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="North"
          active={active === 'North'}
        >
          North
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="East"
          active={active === 'East'}
        >
          East
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="Central"
          active={active === 'Central'}
        >
          Central
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="South"
          active={active === 'South'}
        >
          South
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="San Fernando & Environs"
          active={active === 'San Fernando & Environs'}
        >
          San Fernando &amp; Environs
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="#"
          onClick={onClick}
          data-filter="Tobago"
          active={active === 'Tobago'}
        >
          Tobago
        </NavLink>
      </NavItem>
    </Nav>
  );
}

export default RegionMenu;
