import React from 'react';

import Location from './Location';


function RegionList({ items, full }) {
  return (
    <ul className="list-unstyled">
      {items.map(i => <Location key={i.ext} content={i} full={full} />)}
    </ul>
  );
}

export default RegionList;