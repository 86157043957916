import React from 'react';


function Location({ content, full }) {
  if (full) {
    return (
      <li className="mb-4">
        <h5>{content.location}</h5>
        <p className="m-0">{content.address}</p>
        <p className="m-0">{content.area}</p>
        <p className="m-0">{`226-SUBS (Ext ${content.ext})`}</p>
        <a href={`tel:${content.tel}`} className="text-secondary">
          {content.tel}
        </a>
      </li>
    );
  }
  
  return (
    <li className="mb-4">
      <h5>{content.location}</h5>
      <p className="m-0">{`226-SUBS (Ext ${content.ext})`}</p>
      <a href={`tel:${content.tel}`} className="text-secondary">
        {content.tel}
      </a>
    </li>
  );
}

Location.defaultProps = {
  full: true,
}

export default Location;
