import React from 'react';
import { Nav } from 'reactstrap';

import Group from './Group';


function Accordion({ items, groups, active, onClick }) {
  return (
    <Nav vertical>
      {groups.map(group => (
        <Group
          key={group.label}
          filter={group.filter}
          label={group.label}
          onClick={onClick}
          active={active}
          items={items}
        />))}
    </Nav>
  );
}

export default Accordion;
