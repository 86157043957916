import React from 'react';
import { NavItem } from 'reactstrap';

import Trigger from './Trigger';
import Results from './Results';


function Group({ filter, label, onClick, active, items }) {
  return (
    <NavItem>
      <Trigger
        filter={filter}
        label={label}
        onClick={onClick}
        active={active}
      />
      <Results
        filter={filter}
        label={label}
        onClick={onClick}
        active={active}
        items={items}
      />
    </NavItem>
  );
}

export default Group;