import React from 'react';
import { Collapse, Container } from 'reactstrap';

import RegionList from '../RegionList';


function Results({ active, filter, items }) {
  const groupItems = items.filter(i => filter ? i.region === filter : true);
  
  return (
    <Collapse isOpen={active === filter}>
      <Container fluid className="mt-4">
        <RegionList items={groupItems} full={false} />
      </Container>
    </Collapse>
  );
}

export default Results;
